import React from 'react';
import { Menu, Spin, Avatar } from 'antd';
import { LogOut } from 'lucide-react';
import useAuth from '@hooks/use-auth';

interface AvatarMenuProps {
  photoUrl: string;
  displayName: string;
}

const AvatarMenu: React.FC<AvatarMenuProps> = ({ photoUrl, displayName }) => {
  const { data, logout } = useAuth();

  const getInitials = (name: string) => {
    return name
      .split(' ')
      .map(word => word[0])
      .join('')
      .toUpperCase()
      .slice(0, 2);
  };

  const { flatPermissions = {} } = data || {};
  let roleName = 'User';
  if (flatPermissions['superuser']) {
    roleName = 'SuperUser';
  } else if (flatPermissions['manager']) {
    roleName = 'Manager';
  } else if (flatPermissions['edit']) {
    roleName = 'User';
  } else if (flatPermissions['view']) {
    roleName = 'Viewer';
  } else if (
    data?.permissions.length === 1 &&
    data?.permissions[0] === 'finance'
  ) {
    roleName = 'Finance';
  }

  const menuItems = [
    {
      key: 'role',
      label: <span style={{ color: '#ababab' }}>{roleName.toUpperCase()}</span>,
    },
    {
      key: 'divider',
      type: 'divider',
    },
    {
      key: 'logout',
      label: 'Logout',
      icon: <LogOut size={16} />,
      onClick: () => logout(),
      danger: true,
    },
  ];

  return (
    <Spin spinning={false}>
      <Menu
        mode="horizontal"
        triggerSubMenuAction="click"
        items={[
          {
            key: 'expand',
            icon: (
              <Avatar src={photoUrl} size="small">
                {!photoUrl && getInitials(displayName)}
              </Avatar>
            ),
            children: menuItems,
          },
        ]}
      />
    </Spin>
  );
};

export default AvatarMenu;
