import React from 'react';
import { Button } from 'antd';

interface ViewModeButtonsProps {
  viewMode: 'documents' | 'penalty';
  setViewMode: (mode: 'documents' | 'penalty') => void;
  loading: boolean;
}

const ViewModeButtons: React.FC<ViewModeButtonsProps> = ({
  viewMode,
  setViewMode,
  loading,
}) => {
  return (
    <>
      <Button
        type={viewMode === 'documents' ? 'primary' : 'default'}
        onClick={() => setViewMode('documents')}
        disabled={loading}
      >
        View per Documents
      </Button>
      <Button
        type={viewMode === 'penalty' ? 'primary' : 'default'}
        onClick={() => setViewMode('penalty')}
        disabled={loading}
      >
        View per Penalty
      </Button>
    </>
  );
};

export default ViewModeButtons;
