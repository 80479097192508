import React from 'react';
import { Button, Dropdown, Menu, notification } from 'antd';

import { FilePlus, Landmark, Trash, EllipsisVertical } from 'lucide-react';
import useModal, { IModalPayload } from '@hooks/use-modal';
import useAuth from '@hooks/use-auth';

const ActionCell = ({
  data,
  processing,
  isPenaltyView,
}: {
  data: any;
  processing: boolean;
  isPenaltyView: boolean;
}) => {
  const auth = useAuth();

  const { flatPermissions = {} } = auth.data || {};

  const actions = [];
  const [, openStatusModal] =
    useModal<IModalPayload<{ ids: string[] }>>('invoice-account');

  const [, openDeleteModal] =
    useModal<IModalPayload<{ id: string }>>('invoice-delete');

  const [, openCompensationModal] =
    useModal<IModalPayload<{ ids: string[] }>>('invoice-compensate');

  const [, openCreateRdaModal] =
    useModal<IModalPayload<{ ids: string[]; type: string }>>('invoice-rda');

  if (data.type === 'RDP') {
    actions.push({
      permissions: ['manager', 'superuser', 'finance'],
      label: 'Create RDA',
      icon: <FilePlus size={14} />,
      onClick: async () => {
        try {
          const type = isPenaltyView ? 'penalty' : 'invoice';
          openCreateRdaModal({
            isOpen: true,
            payload: {
              ids: isPenaltyView ? [data._id] : [data.invoice_number],
              type: type,
            },
          });
        } catch (error) {
          notification.error({
            message: 'Failed to create RDA',
            description: error as string,
          });
        }
      },
    });
  }

  if (data.status === 'ACCOUNTED' && !isPenaltyView) {
    actions.push({
      permissions: ['manager', 'superuser', 'finance'],
      label: 'Compensate',
      icon: <Landmark />,
      onClick: async () => {
        openCompensationModal({ isOpen: true, payload: { ids: [data._id] } });
      },
    });
  }

  if (data.status === 'INVOICED' && !isPenaltyView) {
    actions.push({
      permissions: ['manager', 'superuser', 'finance'],
      label: 'Delete',
      icon: <Trash size={14} />,
      onClick: async () => {
        openDeleteModal({ isOpen: true, payload: { id: data._id } });
      },
    });
    if (!isPenaltyView) {
      actions.push({
        permissions: ['manager', 'superuser', 'finance'],
        label: 'Account',
        icon: <Landmark size={14} />,
        onClick: async () => {
          openStatusModal({ isOpen: true, payload: { ids: [data._id] } });
        },
      });
    }
  }
  if (actions.length === 0) {
    return null;
  }

  const getActionItem = (item: any) => {
    const { permissions = [] } = item;
    const hasPermissions = permissions.length
      ? permissions.some((role: any) => flatPermissions[role])
      : true;

    if (hasPermissions) {
      return (
        <Menu.Item onClick={item.onClick} disabled={processing}>
          {item.icon} {item.label}
        </Menu.Item>
      );
    }
    return null;
  };

  const actionsItems = actions.map(item => getActionItem(item));

  return (
    <Dropdown overlay={<Menu>{actionsItems}</Menu>}>
      <Button type="text" icon={<EllipsisVertical size={14} />} />
    </Dropdown>
  );
};

export default ActionCell;
