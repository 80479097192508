import { Moment } from 'moment';

export function formatDate(date: any) {
  date = new Date(date);
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
}

export function getAttachmentType(attachmentUrl: string) {
  let url = attachmentUrl.toLowerCase().trim();
  if (url.includes('.pdf')) return 'pdf';
  else if (url.includes('.xls') || url.includes('.xlsx')) return 'doc';
  else if (url.includes('.mp3') || url.includes('.aac')) return 'audio';
  else if (url.includes('.eml') || url.includes('.msg')) return 'email';
  else if (url.includes('mp4')) return 'video';
  else return 'image';
}

export function formatMomentRange(range: Moment[] | string | undefined) {
  try {
    if (range && Array.isArray(range)) {
      const [startDate, endDate] = range;

      if (startDate && endDate) {
        return [startDate.startOf('day'), endDate.endOf('day')];
      }
    }
    return undefined;
  } catch (error) {
    console.error(error);
    return undefined;
  }
}
