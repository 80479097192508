import React from 'react';
import { Route, Routes } from 'react-router-dom';

import AppProvider from '@components/AppProvider';
import AppLayout from '@components/AppLayout';
import AuthLayout from '@components/AuthLayout';
import AdminLayout from '@components/AdminLayout';
import { SidebarProvider } from '@contexts/SidebarContext';

import PenaltiesAnalytics from '@modules/PenaltiesAnalytics';
import HomePage from '@modules/HomePage';
// import LoginPage from '@modules/LoginPage';
import LoginWithGooglePage from '@modules/LoginWithGooglePage';
import NotFoundPage from '@modules/NotFoundPage';

import '@styles/global.scss';
import '@styles/ant.less';
import AnalyticsLayout from '@components/AnalyticsLayout';
import FinancialPage from '@modules/FinancialPage';
import TeamPage from '@modules/TeamPage';
import CarriersPage from '@modules/CarrierPage';

function App() {
  return (
    <AppProvider>
      <SidebarProvider>
        <AppLayout>
          <Routes>
            <Route path="/auth" element={<AuthLayout />}>
              {/*<Route path="/auth/login" element={<LoginPage />} />*/}
              <Route path="login" element={<LoginWithGooglePage />} />
            </Route>

            {/* Financial route at root level */}
            <Route path="/financial" element={<FinancialPage />} />

            <Route path="/" element={<AdminLayout />}>
              <Route path="/team" element={<TeamPage />} />
              <Route path="/carriers" element={<CarriersPage />} />

              <Route path="/" element={<HomePage />} />
              <Route path="*" element={<NotFoundPage />} />
            </Route>

            <Route path="/analytics" element={<AnalyticsLayout />}>
              <Route path="/analytics" element={<PenaltiesAnalytics />} />
            </Route>

            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </AppLayout>
      </SidebarProvider>
    </AppProvider>
  );
}

export default App;
