import Joi from 'joi';

export const PenaltySchema = Joi.object({
  _id: Joi.string(),
  truck_plate: Joi.string(),
  trailer_plate: Joi.string(),
  week: Joi.number().min(0).required(),
  sennder_reference: Joi.string().required(),
  customer_reference: Joi.string().required(),
  carrier_id: Joi.string().required(),
  carrier_name: Joi.string(),
  status: Joi.allow(
    'APPLIED',
    'NOT_APPLIED',
    'PENDING',
    'BILLABLE',
    'BILLED',
    'TO_REVIEW',
  ).default('PENDING'),
  line_code: Joi.string(),
  order_date: Joi.date().required(),
  received_on: Joi.date().required(),
  delay_type: Joi.string(),
  gps_tracked: Joi.boolean().default(false),
  created_at: Joi.date(),
  justified_at: Joi.date(),
  billed_at: Joi.date(),
  expiration_date: Joi.date().required(),
  initial_delay_minutes: Joi.number().min(0).required(),
  delay_minutes: Joi.number().min(0).required(),
  tour_composition: Joi.string(),
  tour_km: Joi.number().min(Joi.ref('route_km')).required(),
  route_composition: Joi.string(),
  route_km: Joi.number().min(-1).required(),
  initial_penalty_percentage: Joi.number().min(0.0).max(1.0).required(),
  penalty_percentage: Joi.number().min(0.0).max(1.0).required(),
  sda_penalty_cost: Joi.number().min(1),
  total_net_price: Joi.number().min(0).required(),
  total_net_cost: Joi.number().min(0).required(),
  integration_request_body: Joi.string().max(200),
  processing_category: Joi.string(),
  processing_body: Joi.string(),
  planned_departure_at: Joi.date().required(),
  invoice_number: Joi.number(),
  'actual_departure_at.timestamp': Joi.date().required(),
  'actual_departure_at.source': Joi.string().required(),
  planned_arrival_at: Joi.date().required(),
  'actual_arrival_at.timestamp': Joi.date().required(),
  'actual_arrival_at.source': Joi.string().required(),
  tags: Joi.string(),
  'justification.category': Joi.allow(
    'BROKEN_VEHICLE',
    'WRONG_DOCUMENT',
    'EMPTY_VEHICLE',
    'TRAVEL_TIME',
    'HUB_DELAY',
    'TRAFFIC',
    'FERRY_DELAY',
    'VEHICLE_ACCIDENT',
    'POLICE_STOP',
    'HUB_OPENING',
    'OTHER',
  ),
  'justification.body': Joi.string(),
  'justification.attachments_url': Joi.array(),
  'thread.id': Joi.string(),
  'thread.subscribers': Joi.string(),
  reviewed_at: Joi.date(),
  revied_time: Joi.number(),
  review_author: Joi.string(),
  billed_amount: Joi.number(),
  billed_undo_at: Joi.date(),
  billed_undo_author: Joi.string(),
  billed_undo_invoice_number: Joi.number(),
  internal_files: Joi.array().items(
    Joi.object({
      file_type: Joi.string().required(),
      url: Joi.string().uri().required(),
      file_name: Joi.string().required(),
    }),
  ),
});

export const PenaltyNoShowImportSchema = Joi.object({
  week: Joi.number().required(),
  sennder_reference: Joi.string().required(),
  customer_reference: Joi.string().required(),
  carrier_id: Joi.string().required(),
  carrier_name: Joi.string().required(),
  status: Joi.string().required(),
  line_code: Joi.string().required(),
  order_date: Joi.date().required(),
  received_on: Joi.date().required(),
  delay_type: Joi.string().required().allow('no show'),
  tour_composition: Joi.string().required(),
  route_composition: Joi.string().required(),
  initial_penalty_percentage: Joi.number().required(),
  penalty_percentage: Joi.number().required(),
  sda_penalty_cost: Joi.number().optional(),
  total_net_cost: Joi.number().required(),
  tags: Joi.string().optional(),
});
