import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import useModal, { IModalPayload } from '@hooks/use-modal';

import Uploader from './components/Uploader';
import FilesUpload from './components/FilesUpload';
import { Import } from 'lucide-react';

const UploadInternalFilesModal = () => {
  const [modal, setModal] = useModal<
    IModalPayload<{ sennderRef?: string | null; competence: string | null }>
  >('upload-internal-files');
  const { sennderRef = null, competence = null } = modal?.payload || {};

  const [data, setData] = useState<any[]>([]);
  const [confirmedData, setConfirmedData] = useState<boolean>(false);

  const onClose = () => {
    setModal({ isOpen: false, payload: null });
    setConfirmedData(false);
    setData([]);
  };

  const onChange = (files: any[]) => {
    setData(files);
  };

  const confirmValues = () => {
    setConfirmedData(true);
  };

  return (
    <Modal
      destroyOnClose
      centered
      width={720}
      onCancel={onClose}
      footer={null}
      title={
        <>
          <Import size={14} className={'mr-2'} />
          Upload Internal Files
          {data?.length ? <span className="ml-1">({data.length})</span> : null}
        </>
      }
      visible={modal?.isOpen || false}
    >
      {!confirmedData ? (
        <div>
          <Uploader
            onFilesChange={onChange}
            data={data}
            sennderRef={sennderRef}
          />
          <Button
            onClick={confirmValues}
            type="primary"
            disabled={!data.length}
            style={{ marginTop: '5px' }}
          >
            Conferma
          </Button>
        </div>
      ) : (
        <FilesUpload
          data={data}
          sennderRef={sennderRef}
          competence={competence}
        />
      )}
    </Modal>
  );
};

export default UploadInternalFilesModal;
