import React from 'react';
import { Button, Dropdown, Menu } from 'antd';

import useAuth from '@hooks/use-auth';
import { IUser } from '@services/user';
import { Edit2, MoreVertical, Trash } from 'lucide-react';
import useModal, { IModalPayload } from '@hooks/use-modal';

const ActionCell = ({ data }: { data: IUser }) => {
  const { data: authInfo } = useAuth();
  const { flatPermissions = {} } = authInfo || {};
  const [, addUser] = useModal<IModalPayload<any>>('add-user');
  const [, deleteUser] =
    useModal<IModalPayload<{ userId: string }>>('delete-user');

  const normalActions = [
    {
      permissions: ['manager'],
      icon: <Edit2 size={16} />,
      onClick: () =>
        addUser({ isOpen: true, payload: { editing: true, data: data } }),
      label: 'Edit',
    },
    {
      permissions: ['manager'],
      icon: <Trash size={16} />,
      onClick: () =>
        deleteUser({ isOpen: true, payload: { userId: data._id } }),
      label: 'Delete',
    },
  ];

  return (
    <Dropdown
      trigger={['click']}
      placement="bottomRight"
      overlay={
        <Menu
          items={normalActions
            .filter(item => {
              const { permissions = [] } = item;
              return permissions.length
                ? permissions.some(role => {
                    return flatPermissions[role];
                  })
                : true;
            })
            .map(item => {
              return {
                ...item,
                key: item.label,
              };
            })}
        />
      }
    >
      <Button type="text">
        <MoreVertical size={20} />
      </Button>
    </Dropdown>
  );
};

export default ActionCell;
