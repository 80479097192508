import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Popover, Table, notification } from 'antd';
import type { ColumnsType, TableProps } from 'antd/lib/table';
import { useQuery, useQueryClient } from 'react-query';
import { getInvoices, getStats } from '@services/invoice';
import { IInvoice } from '@services/invoice/invoice.model';
import ActionCell from './ActionCell';
import BulkActions from './BulkActions';
import { CircleAlert } from 'lucide-react';

interface DocumentsTableProps {
  query: any;
  setQuery: (query: any) => void;
  selectedRows: React.Key[];
  setSelectedRows: (rows: React.Key[]) => void;
  processingIds: string[];
  setProcessingIds: (ids: string[]) => void;
  filter: any;
  setStats: (stats: any) => void;
  setLoading: (loading: boolean) => void;
  viewMode: string;
}

const DocumentsTable: React.FC<DocumentsTableProps> = ({
  query,
  setQuery,
  selectedRows,
  setSelectedRows,
  processingIds,
  filter,
  setStats,
  setLoading,
  viewMode,
}) => {
  const queryClient = useQueryClient();
  const refContainer = useRef<HTMLDivElement>(null);
  const [data, setData] = useState<IInvoice[]>([]);
  const [pagination, setPagination] = useState({
    current: 1,
    total: 0,
    pageSize: 100,
    showSizeChanger: false,
    position: ['bottomCenter' as 'bottomCenter'],
  });

  const mappedDocuments = useMemo(
    () =>
      data
        .filter(invoice => selectedRows.includes(invoice.invoice_number))
        .map(invoice => ({
          invoice_number: invoice.invoice_number,
          _id: invoice._id,
          status: invoice.status,
          row_key: null,
        })),
    [selectedRows, data],
  );

  const columns: ColumnsType<IInvoice> = [
    {
      title: 'Document Number',
      dataIndex: 'invoice_number',
      key: 'invoice_number',
      sorter: (a, b) => Number(a.invoice_number) - Number(b.invoice_number),
      width: 150,
      render: (text: string, record: IInvoice) => (
        <a href={record.invoice_doc} target="_blank" rel="noopener noreferrer">
          {text}
        </a>
      ),
    },
    {
      title: 'Carrier',
      dataIndex: 'carrier',
      key: 'carrier',
      render: (text: { carrier_name: string }) => (
        <p>
          {text?.carrier_name || (
            <span>
              <CircleAlert size={14} />
              Unknown
            </span>
          )}
        </p>
      ),
      width: 150,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      sorter: (a, b) => a.amount - b.amount,
      width: 150,
    },
    {
      title: 'Competence',
      dataIndex: 'competence',
      key: 'competence',
      render: text =>
        `${new Date(text)
          .toLocaleString('default', { month: 'short', year: 'numeric' })
          .toUpperCase()} `,
      width: 150,
    },
    {
      title: 'Emission',
      dataIndex: 'created_at',
      key: 'created_at',
      render: text => new Date(text).toLocaleDateString('it-IT'),
      width: 150,
    },
    {
      title: 'Status',
      key: 'status',
      render: (data: IInvoice) => {
        return (
          <Popover
            content={
              <div>
                <p>
                  Accounted on:{' '}
                  {data.accounted_at ? (
                    new Date(data.accounted_at)
                      .toLocaleString('it-IT', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                      })
                      .replace(',', '')
                  ) : (
                    <b>No date found</b>
                  )}
                </p>
                <p>
                  Compensated on:{' '}
                  {data.compensated_at ? (
                    new Date(data.compensated_at)
                      .toLocaleString('it-IT', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                      })
                      .replace(',', '')
                  ) : (
                    <b>No date found</b>
                  )}
                </p>
              </div>
            }
            title="Compensation and Accounting"
          >
            {data.status}
          </Popover>
        );
      },
      width: 100,
    },

    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: 100,
    },
    {
      key: 'actions',
      width: 84,
      fixed: 'right',
      render: (data: IInvoice) => {
        return (
          <ActionCell
            isPenaltyView={false}
            data={data}
            processing={processingIds.includes(data._id)}
          />
        );
      },
    },
  ];

  useEffect(() => {
    // Cleanup function to cancel pending queries
    return () => {
      queryClient.cancelQueries(['documents-invoices', filter, query]);
    };
    // eslint-disable-next-line
  }, [queryClient]);

  const { isFetching } = useQuery(
    ['documents-invoices', filter, query],
    () => getInvoices({ ...query, ...filter }),
    {
      enabled: viewMode === 'documents',
      staleTime: 0,
      cacheTime: 0,
      onSuccess: async (res: { data: IInvoice[]; total: number }) => {
        if (viewMode === 'documents') {
          setData(res.data);
          setPagination(prev => ({ ...prev, total: res.total }));
          const stats = await getStats(filter);
          setStats(stats);
        }
      },
      onError: (error: { message: string }) => {
        notification.error({
          message: 'Error fetching invoices',
          description: error.message || error.toString(),
        });
      },
    },
  );

  useEffect(() => {
    setLoading(isFetching);
  }, [isFetching, setLoading]);

  const onSelectChange = (rows: React.Key[]) => {
    setSelectedRows(rows);
  };

  const onChangeTable: TableProps<IInvoice>['onChange'] = tablePagination => {
    const newPage = tablePagination.current ?? 1;
    setQuery({ ...query, page: newPage });
    setPagination(prev => ({ ...prev, current: newPage }));
  };

  return (
    <div
      ref={refContainer}
      style={{
        height: 'calc(100% - 20px)',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div style={{ flex: 1, overflow: 'auto', position: 'relative' }}>
        <Table
          rowSelection={{
            selectedRowKeys: selectedRows,
            onChange: onSelectChange,
          }}
          loading={isFetching}
          onChange={onChangeTable}
          columns={columns}
          pagination={{
            ...pagination,
            style: {
              position: 'sticky',
              bottom: 0,
              backgroundColor: 'white',
              padding: '10px 0',
              borderTop: '1px solid #f0f0f0',
              zIndex: 1000,
            },
          }}
          rowKey="invoice_number"
          dataSource={data}
          scroll={{
            x: '100%',
            y: 'calc(100vh - 400px)',
          }}
        />
      </div>
      {selectedRows.length > 0 && (
        <BulkActions documents={mappedDocuments} isPenaltyView={false} />
      )}
    </div>
  );
};

export default DocumentsTable;
