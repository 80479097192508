import React, { useState } from 'react';
import { Button, Modal, notification, Space, Spin } from 'antd';
import { useMutation, useQueryClient } from 'react-query';

import { handleAPIError } from '@services/base';
import useModal, { IModalPayload } from '@hooks/use-modal';

import { IInvoice } from '@services/invoice/invoice.model';
import { deleteInvoice } from '@services/invoice';
import { Trash } from 'lucide-react';

const DeleteInvoiceModal = () => {
  const [modal, setModal] = useModal<IModalPayload<any>>('invoice-delete');
  const [data, setData] = useState<IInvoice[]>([]);
  const onClose = () => {
    setModal({ isOpen: false, payload: null });
    setData([]);
  };

  const queryClient = useQueryClient();

  const { isLoading: isSubmitting, mutate } = useMutation(
    () => {
      return deleteInvoice(modal?.payload.id);
    },
    {
      onSuccess: async res => {
        onClose();
        await queryClient.invalidateQueries('invoices');
        if (!res?.error) {
          notification.success({
            message: 'Success',
            description: 'Invoice deleted successfully',
          });
        } else {
          notification.error({
            message: 'Error',
            description: 'Cannot delete invoice',
          });
        }
      },
      onError: handleAPIError,
    },
  );

  const submit = () => {
    mutate();
  };

  return (
    <Modal
      destroyOnClose
      centered
      width={720}
      onCancel={onClose}
      footer={null}
      title={
        <>
          <Trash size={14} className={'mr-2'} />
          Delete Invoice
          {data?.length ? <span className="ml-1">({data.length})</span> : null}
        </>
      }
      visible={modal?.isOpen || false}
    >
      <Spin spinning={isSubmitting}>
        <div
          style={{
            maxHeight: '280px',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <p>
            Are you sure you want do delete the invoice?{' '}
            <b>This actions cannot be undone</b>
          </p>
        </div>
        <div className={'text-center'}>
          <Space align={'center'}>
            <Button onClick={onClose}>Cancel</Button>
            <Button
              onClick={submit}
              type="primary"
              icon={<Trash size={14} className="mr-2" />}
            >
              Confirm
            </Button>
          </Space>
        </div>
      </Spin>
    </Modal>
  );
};

export default DeleteInvoiceModal;
