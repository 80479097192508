import { Layout } from 'antd';
import { Navigate, useLocation } from 'react-router-dom';
import React from 'react';
import { useSidebar } from '@contexts/SidebarContext';
import useAuth from '@hooks/use-auth';
import AppMenu from '@components/AppMenu';
import styles from '@components/AdminLayout/styles.module.scss';

import Widget from './Widget';
import AccountInvoiceModal from './components/AccountInvoiceModal';
import CompensateInvoiceModal from './components/CompensateInvoiceModal';
import DeleteInvoiceModal from './components/DeleteInvoiceModal';
import CreateInvoiceRdaModal from './components/CreateInvoiceRdaModal';
import AvatarMenu from '@components/AvatarMenu';

const { Header, Content } = Layout;

const FinancialPage = () => {
  const { collapsed } = useSidebar();
  let { isAuthenticating, data } = useAuth();
  let location = useLocation();

  if (isAuthenticating) {
    return null;
  }

  if (!data) {
    return <Navigate to="/auth/login" state={{ from: location }} replace />;
  }

  const { photoURL, displayName } = data;

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header
        className={styles.header}
        style={{
          position: 'fixed',
          zIndex: 100,
          width: '100%',
          height: 64,
          padding: '0 24px',
          top: 0,
          display: 'flex',
          justifyContent: 'flex-end', // Changed to flex-end
          alignItems: 'center',
          backgroundColor: '#fff',
          borderBottom: '1px solid #f0f0f0',
          boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.03)', // Optional: adds subtle shadow
        }}
      >
        <AvatarMenu photoUrl={photoURL} displayName={displayName} />
      </Header>
      <AppMenu />
      <Layout
        style={{
          marginLeft: collapsed ? 80 : 250,
          marginTop: 64,
          transition: 'all 0.2s',
        }}
      >
        <Content
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: 280,
            transition: 'margin-left 0.2s',
          }}
        >
          <Layout style={{ height: '100%' }}>
            <Widget />
            <AccountInvoiceModal />
            <CompensateInvoiceModal />
            <DeleteInvoiceModal />
            <CreateInvoiceRdaModal />
          </Layout>
        </Content>
      </Layout>
    </Layout>
  );
};

export default FinancialPage;
