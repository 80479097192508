import React from 'react';
import { Button, Dropdown, Menu } from 'antd';
import { IPenalty } from '@services/penalty';
import useModal, { IModalPayload } from '@hooks/use-modal';
import { useExportPenalty } from '@hooks/use-export-penalty';
import useAuth from '@hooks/use-auth';
import {
  ChevronLeft,
  CircleCheck,
  EllipsisVertical,
  File,
  FileText,
  GitPullRequestArrow,
  Info,
  LoaderCircle,
  Logs,
  /*   MessageCircleMore,
   */ PenLine,
  Save,
  Share,
  Tags,
  Trash,
  X,
} from 'lucide-react';

const ActionCell = ({
  data,
  processing,
}: {
  data: IPenalty;
  processing: boolean;
}) => {
  const [, openDetail] =
    useModal<IModalPayload<{ id: string }>>('penalty-detail');
  const [, updatePenalty] =
    useModal<IModalPayload<{ ids: string[]; type: string }>>('update-penalty');
  const [, openCarrierDetails] =
    useModal<IModalPayload<{ id: string }>>('carrier-details');

  const [, uploadInternalFiles] = useModal<
    IModalPayload<{ sennderRef: string | null; competence?: string | null }>
  >('upload-internal-files');

  const { _id, carrier_id } = data;
  const { isExporting, exportToCSV } = useExportPenalty();
  const { data: authInfo } = useAuth();
  const { flatPermissions = {} } = authInfo || {};

  const normalActions = [
    {
      permissions: ['edit', 'view', 'superuser', 'manager'],
      icon: isExporting ? <LoaderCircle size={14} /> : <Share size={14} />,
      label: 'Export',
      onClick: () => {
        exportToCSV({ ids: [_id] });
      },
    },
    {
      icon: <Info size={14} />,
      permissions: ['edit', 'view', 'superuser', 'manager'],
      label: 'Detail',
      onClick: () => {
        openDetail({
          isOpen: true,
          payload: {
            id: _id,
          },
        });
      },
    },

    {
      permissions: ['edit', 'superuser', 'manager'],
      statuses: [
        'APPLIED',
        'NOT_APPLIED',
        'PENDING',
        'BILLABLE',
        'BILLED',
        'TO_REVIEW',
      ],
      icon: <PenLine size={14} />,
      label: 'Edit Penalty',
      onClick: () => {
        updatePenalty({
          isOpen: true,
          payload: {
            ids: [_id],
            type: 'edit',
          },
        });
      },
    },
    {
      permissions: ['edit', 'superuser', 'manager'],
      icon: <File size={14} />,
      label: 'Upload internal files',
      onClick: () => {
        uploadInternalFiles({
          isOpen: true,
          payload: {
            sennderRef: data.sennder_reference || null,
            competence: data.route_composition || null,
          },
        });
      },
    },
    {
      permissions: ['edit', 'superuser', 'manager'],
      icon: <Logs size={14} />,
      label: 'View Carrier',
      onClick: () => {
        openCarrierDetails({
          isOpen: true,
          payload: {
            id: carrier_id as unknown as string,
          },
        });
      },
    },

    {
      permissions: ['edit', 'superuser', 'manager'],
      statuses: ['BILLABLE'],
      icon: <FileText size={14} />,
      label: 'Invoice penalty',
      onClick: () => {
        updatePenalty({
          isOpen: true,
          payload: {
            type: 'invoice',
            ids: [_id],
          },
        });
      },
    },
    {
      permissions: ['edit', 'superuser', 'manager'],
      statuses: ['PENDING', 'TO_REVIEW'],
      icon: <GitPullRequestArrow size={14} />,
      label: 'Request information',
      onClick: () => {
        updatePenalty({
          isOpen: true,
          payload: {
            type: 'request-information',
            ids: [_id],
          },
        });
      },
    },
    {
      permissions: ['edit', 'superuser', 'manager'],
      icon: <Tags size={14} />,
      label: 'Assign tag',
      onClick: () => {
        updatePenalty({
          isOpen: true,
          payload: {
            type: 'assign-tag',
            ids: [_id],
          },
        });
      },
    },

    {
      permissions: ['manager'],
      icon: <ChevronLeft size={14} />,
      label: 'Reset to pending',
      onClick: () => {
        updatePenalty({
          isOpen: true,
          payload: {
            type: 'reset-penalty',
            ids: [_id],
          },
        });
      },
    },

    /* {
      icon: <MessageCircleMore size={14} />,
      label: 'Add comment',
      onClick: () => {
        updatePenalty({
          isOpen: true,
          payload: {
            type: 'comment',
            ids: [_id],
          },
        });
      },
    }, */
    {
      permissions: ['edit', 'superuser', 'manager'],
      statuses: [
        'APPLIED',
        'NOT_APPLIED',
        'PENDING',
        'BILLABLE',
        'BILLED',
        'TO_REVIEW',
      ],
      icon: <Save size={14} />,
      label: 'Archive',
      onClick: () => {
        updatePenalty({
          isOpen: true,
          payload: {
            type: 'archive',
            ids: [_id],
          },
        });
      },
    },
    {
      permissions: ['edit', 'superuser', 'manager'],
      statuses: [
        'APPLIED',
        'NOT_APPLIED',
        'PENDING',
        'BILLABLE',
        'ARCHIVED',
        'TO_REVIEW',
      ],
      icon: <Trash size={14} />,
      label: 'Delete',
      onClick: () => {
        updatePenalty({
          isOpen: true,
          payload: {
            type: 'delete',
            ids: [_id],
          },
        });
      },
    },
  ];

  const processingActions = [
    {
      icon: <Info size={14} />,
      permissions: ['edit', 'view', 'superuser', 'manager'],
      label: 'Detail',
      onClick: () => {
        openDetail({
          isOpen: true,
          payload: {
            id: _id,
          },
        });
      },
    },
    {
      permissions: ['edit', 'superuser', 'manager'],
      statuses: ['PENDING', 'NOT_APPLIED', 'TO_REVIEW'],
      icon: <CircleCheck size={14} />,
      label: 'Apply penalty',
      onClick: () => {
        updatePenalty({
          isOpen: true,
          payload: {
            ids: [_id],
            type: 'apply',
          },
        });
      },
    },
    {
      permissions: ['edit', 'superuser', 'manager'],
      statuses: ['PENDING', 'APPLIED', 'TO_REVIEW'],
      icon: <X size={14} />,
      label: 'Remove penalty',
      onClick: () => {
        updatePenalty({
          isOpen: true,
          payload: {
            type: 'remove',
            ids: [_id],
          },
        });
      },
    },
  ];

  const actions = processing ? processingActions : normalActions;
  return (
    <Dropdown
      trigger={['click']}
      placement="bottomRight"
      overlay={
        <Menu
          items={actions
            .filter(item => {
              const { permissions = [] } = item;
              return permissions.length
                ? permissions.some(role => {
                    return flatPermissions[role];
                  })
                : true;
            })
            .filter(({ statuses }) => {
              return !statuses || statuses?.indexOf(data.status || '') >= 0;
            })
            .map(item => {
              return {
                ...item,
                key: item.label,
              };
            })}
        />
      }
    >
      <Button type="text">
        <EllipsisVertical size={14} style={{ fontSize: '20px' }} />
      </Button>
    </Dropdown>
  );
};

export default ActionCell;
