import React from 'react';
import { Button, Modal, notification, Space, Spin } from 'antd';
import { useMutation, useQueryClient } from 'react-query';

import { handleAPIError } from '@services/base';
import useModal, { IModalPayload } from '@hooks/use-modal';
import { deleteUser } from '@services/user';
import { Trash } from 'lucide-react';

export const DeleteUserModal = () => {
  const [modal, setModal] =
    useModal<IModalPayload<{ userId: string }>>('delete-user');
  const queryClient = useQueryClient();

  const onClose = () => {
    setModal({ isOpen: false, payload: null });
  };

  const { isLoading: isSubmitting, mutate } = useMutation(
    () => deleteUser(modal?.payload?.userId as string),
    {
      onSuccess: async () => {
        onClose();
        await queryClient.invalidateQueries('users');
        notification.success({
          message: 'Success',
          description: 'User deleted successfully',
        });
      },
      onError: handleAPIError,
    },
  );

  const submit = () => {
    mutate();
  };

  return (
    <Modal
      destroyOnClose
      centered
      width={720}
      onCancel={onClose}
      footer={null}
      title={
        <>
          <Trash size={14} className={'mr-2'} />
          Delete User
        </>
      }
      visible={modal?.isOpen || false}
    >
      <Spin spinning={isSubmitting}>
        <div
          style={{
            maxHeight: '280px',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <p>Are you sure you want to delete this user? </p>
        </div>
        <div className={'text-center'}>
          <Space align={'center'}>
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={submit} type="primary" icon={<Trash size={14} />}>
              Confirm
            </Button>
          </Space>
        </div>
      </Spin>
    </Modal>
  );
};
