import { notification, Typography, Upload } from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { debounce } from 'lodash';
import { Import } from 'lucide-react';

const { Dragger } = Upload;
const { Text } = Typography;

const Uploader = ({
  onFilesChange,
  data,
  sennderRef,
}: {
  onFilesChange: any;
  data: any;
  sennderRef: string | null;
}) => {
  const onChange = debounce((info: UploadChangeParam<any>) => {
    const files = info.fileList;
    const gpsPattern = sennderRef
      ? /gps\.[^.]+$/
      : /^\d+a_.+_gps\.[A-Za-z0-9]+$/;
    const speedPattern = sennderRef
      ? /speed\.[^.]+$/
      : /^\d+a_.+_speed\.[A-Za-z0-9]+$/;
    const otherPattern = sennderRef
      ? /other\.[^.]+$/
      : /^\d+a_.+_other\.[A-Za-z0-9]+$/;

    const validFiles = files.filter(file => {
      const fileName = file.name.toLowerCase();
      if (
        fileName.match(gpsPattern) ||
        fileName.match(speedPattern) ||
        fileName.match(otherPattern)
      ) {
        return true;
      } else {
        console.error(`Invalid file name: ${file.name}`);
        notification.error({
          message: `Invalid file name: ${file.name}`,
        });
        return false;
      }
    });

    onFilesChange(validFiles);
  }, 300); // Adjust debounce delay as needed

  const onRemove = (file: UploadFile<any>) => {
    const updatedData = data.filter((item: any) => item.uid !== file.uid);
    onFilesChange(updatedData);
  };

  return (
    <Dragger
      name="file"
      multiple={true}
      onChange={onChange}
      showUploadList={true}
      onRemove={onRemove}
      fileList={data}
      beforeUpload={() => false}
      maxCount={100}
    >
      <Text type="secondary">
        <Import size={14} style={{ fontSize: '40px' }} />
      </Text>
      <div className="my-3"></div>
      <Text type="secondary">
        Click or drag file to this area to upload files
      </Text>
    </Dragger>
  );
};

export default Uploader;
