import React, { useRef, useState } from 'react';
import { Table, TableProps, Layout, Button } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';
import { useQuery } from 'react-query';

import { handleAPIError, IFetchQuery } from '@services/base';
import { getCarriers, ICarrier } from '@services/carrier';
import ActionCell from './components/ActionCell';
import useModal, { IModalPayload } from '@hooks/use-modal';

const { Content } = Layout;

const Widget: React.FC = () => {
  const [query, setQuery] = useState<IFetchQuery>({
    page: 1,
    page_size: 100,
  });
  const refContainer = useRef<HTMLDivElement>(null);
  const [, deleteCarrier] =
    useModal<IModalPayload<{ carrierId: string }>>('delete-carrier');

  const [data, setData] = useState<ICarrier[]>([]);

  const [pagination, setPagination] = useState<any>({
    current: 1,
    total: 0,
    pageSize: 100,
    showSizeChanger: false,
    position: ['bottomCenter'],
  });

  const columns: ColumnsType<ICarrier> = [
    {
      title: 'Name',
      key: 'carrier_name',
      dataIndex: 'carrier_name',
      sorter: (a, b) => {
        const value1 = a.carrier_name || '';
        const value2 = b.carrier_name || '';
        return value1.localeCompare(value2);
      },
      showSorterTooltip: false,
      width: 100,
    },
    {
      title: 'Login email',
      key: 'email',
      dataIndex: 'email',
      sorter: (a, b) => {
        const value1 = a.email || '';
        const value2 = b.email || '';
        return value1.localeCompare(value2);
      },
      showSorterTooltip: false,
      width: 100,
    },
    {
      title: 'Financial Email',
      key: 'financial-email',
      render: (data: ICarrier) => {
        return <span>{data.contacts?.financial}</span>;
      },
      sorter: (a, b) => {
        const value1 = a.contacts?.financial || '';
        const value2 = b.contacts?.financial || '';
        return value1.localeCompare(value2);
      },
      showSorterTooltip: false,
      width: 100,
    },
    {
      title: 'Notification Email',
      key: 'notification-email',
      render: (data: ICarrier) => {
        return <span>{data.contacts?.notifications}</span>;
      },
      sorter: (a, b) => {
        const value1 = a.contacts?.notifications || '';
        const value2 = b.contacts?.notifications || '';
        return value1.localeCompare(value2);
      },
      showSorterTooltip: false,
      width: 100,
    },
    {
      title: 'Address',
      key: 'address',
      dataIndex: 'address',
      sorter: (a, b) => {
        const value1 = a.email || '';
        const value2 = b.email || '';
        return value1.localeCompare(value2);
      },
      showSorterTooltip: false,
      width: 100,
    },
    {
      title: 'VAT',
      key: 'vat',
      dataIndex: 'vat',
      sorter: (a, b) => {
        const value1 = a.vat || '';
        const value2 = b.vat || '';
        return value1.localeCompare(value2);
      },
      showSorterTooltip: false,
      width: 100,
    },
    {
      title: 'Finance ID',
      key: 'finance_id',
      dataIndex: 'finance_id',
      sorter: (a, b) => {
        const value1 = a.finance_id || '';
        const value2 = b.finance_id || '';
        return value1.localeCompare(value2);
      },
      showSorterTooltip: false,
      width: 100,
    },
    {
      fixed: 'right',
      key: 'actions',
      width: 20,
      render: (data: ICarrier) => {
        return <ActionCell data={data} />;
      },
    },
  ];

  const { isFetching } = useQuery(['carriers', query], () => getCarriers(), {
    keepPreviousData: true,
    onSuccess: async res => {
      const carriers = res.carriers || [];

      setData(carriers);
    },
    onError: handleAPIError,
  });

  const onChangeTable: TableProps<ICarrier>['onChange'] = async (
    pag,
    filters,
    sorters,
  ): Promise<void> => {
    let newQuery: IFetchQuery = {
      page: pag.current,
      page_size: 100,
    };

    const { order, columnKey } = sorters as SorterResult<ICarrier>;
    if (order) {
      newQuery = {
        ...newQuery,
        page_size: 100,
        order_by: columnKey,
        order_direction: order,
      };
    }
    if (pag.current !== pagination.current) {
      newQuery = { ...newQuery, page: pag.current, page_size: 100 };

      setPagination({
        ...pagination,
        current: pag.current,
        page_size: 100,
      });
    }
    setQuery(newQuery);
  };

  return (
    <>
      <Content
        className={'bg-white'}
        style={{ borderRadius: '15px', overflow: 'hidden', padding: '5px' }}
      >
        <Button
          onClick={() =>
            deleteCarrier({ isOpen: true, payload: { carrierId: '' } })
          }
          type="primary"
          style={{ display: 'flex', alignItems: 'center' }}
        ></Button>
        <div ref={refContainer} style={{ height: '96%' }}>
          <Table
            loading={isFetching}
            onChange={onChangeTable}
            columns={columns}
            pagination={pagination}
            rowKey="_id"
            dataSource={data}
            scroll={
              refContainer?.current
                ? {
                    y: refContainer.current.clientHeight - 56 - 56,
                    x: refContainer.current.clientWidth,
                  }
                : undefined
            }
          />
        </div>
      </Content>
    </>
  );
};

export default Widget;
