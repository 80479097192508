import React from 'react';
import { Descriptions, Tag } from 'antd';
import { FormattedDate } from 'react-intl';

import { getStatuses, IPenalty } from '@services/penalty';
import { useQuery } from 'react-query';

const General = ({ data }: { data: IPenalty }) => {
  const { data: res } = useQuery('penalty-status', () => getStatuses(), {});
  const statuses = res?.data || [];
  const matched = statuses.find(({ code }) => data?.status === code);

  return (
    <Descriptions size={'middle'} title="General" bordered column={2}>
      <Descriptions.Item label="Penalty ID">{data._id}</Descriptions.Item>
      <Descriptions.Item label="Sennder Reference">
        {data.sennder_reference}
      </Descriptions.Item>
      <Descriptions.Item label="Customer Reference">
        {data.customer_reference}
      </Descriptions.Item>
      <Descriptions.Item label="Carrier">{data.carrier_name}</Descriptions.Item>
      <Descriptions.Item label="Status">
        <Tag color={matched?.color || 'default'}>
          <strong>{matched?.name || data.status}</strong>
        </Tag>
      </Descriptions.Item>
      <Descriptions.Item label="Order Date">
        {data.order_date ? (
          <FormattedDate
            value={data.order_date}
            day="numeric"
            month="numeric"
            year="numeric"
            hour="numeric"
            minute="numeric"
          ></FormattedDate>
        ) : null}
      </Descriptions.Item>
      <Descriptions.Item label="Created at">
        {data.created_at ? (
          <FormattedDate
            value={data.created_at}
            day="numeric"
            month="numeric"
            year="numeric"
            hour="numeric"
            minute="numeric"
          ></FormattedDate>
        ) : null}
      </Descriptions.Item>
      <Descriptions.Item label="Justified at">
        {data.justified_at ? (
          <FormattedDate
            value={data.justified_at}
            day="numeric"
            month="numeric"
            year="numeric"
            hour="numeric"
            minute="numeric"
          ></FormattedDate>
        ) : null}
      </Descriptions.Item>
      <Descriptions.Item label="Billed at">
        {data.billed_at ? (
          <FormattedDate
            value={data.billed_at}
            day="numeric"
            month="numeric"
            year="numeric"
            hour="numeric"
            minute="numeric"
          ></FormattedDate>
        ) : null}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default General;
