import React from 'react';
import { Table, Typography, Button, Tag, Tooltip } from 'antd';
import { getStatuses, IPenalty } from '@services/penalty';
import { useQuery } from 'react-query';
import { Trash, TriangleAlert } from 'lucide-react';

const { Text } = Typography;

const ReferenceCell = (props: { data: IPenalty }): JSX.Element => {
  const {
    data: { sennder_reference, customer_reference },
  } = props;
  return (
    <>
      <Text>{sennder_reference}</Text>
      <br />
      <Text type={'secondary'}>{customer_reference}</Text>
    </>
  );
};

const StatusCell = (props: { data: IPenalty }): JSX.Element | null => {
  const { data: res } = useQuery('penalty-status', () => getStatuses(), {});
  const {
    data: { status, message },
  } = props;

  const matched = status
    ? res?.data?.find(({ code }) => status === code)
    : null;

  return (
    <>
      <Tag color={matched?.color || 'default'} style={{ whiteSpace: 'normal' }}>
        <strong>{matched?.name || status}</strong>
      </Tag>
      {message ? (
        <Tooltip title={message}>
          <Text type="danger" className="mr-2">
            <TriangleAlert size={14} className="mr-1" />
          </Text>
        </Tooltip>
      ) : null}
    </>
  );
};

interface IProps {
  data: IPenalty[];
  removable?: boolean;
  onRemove?: (id: string) => void;
}

const DataGrid = (props: IProps) => {
  const { data, onRemove, removable = true } = props;

  const columns = [
    {
      title: 'Reference',
      render: (data: IPenalty) => {
        return <ReferenceCell data={data} />;
      },
    },
    {
      title: 'Carrier',
      key: 'carrier_name',
      dataIndex: 'carrier_name',
    },
    {
      title: 'Status',
      key: 'status',
      width: 150,
      render: (data: IPenalty) => {
        return <StatusCell data={data} />;
      },
    },
    {
      key: 'actions',
      width: 64,
      render: (data: IPenalty) => {
        return (
          <Button
            type="text"
            onClick={() => {
              if (typeof onRemove === 'function') {
                onRemove(data._id);
              }
            }}
          >
            <Trash size={14} style={{ fontSize: '16px' }} />
          </Button>
        );
      },
    },
  ];

  const disableRemoveButton = data.length === 1 || !removable;

  return (
    <Table
      size={'small'}
      bordered
      columns={disableRemoveButton ? columns.splice(0, 3) : columns}
      rowKey="_id"
      dataSource={data}
      pagination={false}
      scroll={{ y: 200 }}
    />
  );
};

export default DataGrid;
