import React, { useState } from 'react';
import { Button, Modal, notification, Space, Spin } from 'antd';
import { useMutation, useQueryClient } from 'react-query';

import { handleAPIError } from '@services/base';
import useModal, { IModalPayload } from '@hooks/use-modal';

import { IInvoice } from '@services/invoice/invoice.model';
import { undoBillingPerPenalty } from '@services/invoice';
import { undoBilling } from '@services/penalty';
import { CircleCheck, FilePlus } from 'lucide-react';

const CreateInvoiceRdaModal = () => {
  const [modal, setModal] = useModal<IModalPayload<any>>('invoice-rda');
  const [data, setData] = useState<IInvoice[]>([]);
  const onClose = () => {
    setModal({ isOpen: false, payload: null });
    setData([]);
  };

  const queryClient = useQueryClient();

  const { isLoading: isSubmitting, mutate } = useMutation(
    () => {
      return modal.payload.type === 'invoice'
        ? undoBilling(modal?.payload.ids)
        : undoBillingPerPenalty(modal?.payload.ids);
    },
    {
      onSuccess: async res => {
        onClose();
        await queryClient.invalidateQueries('invoices');
        res.forEach((result: any) => {
          if (result.success) {
            notification.success({
              message: `(${result.id}) RDA created successfully`,
            });
          } else
            notification.error({
              description: result.error,
              message: `(${result.id}) Cannot create RDA`,
            });
        });
      },
      onError: handleAPIError,
    },
  );

  const submit = () => {
    mutate();
  };

  return (
    <Modal
      destroyOnClose
      centered
      width={720}
      onCancel={onClose}
      footer={null}
      title={
        <>
          <FilePlus size={14} className={'mr-2'} />
          Create RDA
          {data?.length ? <span className="ml-1">({data.length})</span> : null}
        </>
      }
      visible={modal?.isOpen || false}
    >
      <Spin spinning={isSubmitting}>
        <div
          style={{
            maxHeight: '280px',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <b>Are you sure you want to create an RDA?</b>
        </div>
        <br />
        <div className={'text-center'}>
          <Space align={'center'}>
            <Button onClick={onClose}>Cancel</Button>
            <Button
              onClick={submit}
              type="primary"
              icon={<CircleCheck size={14} className="mr-2" />}
            >
              Confirm
            </Button>
          </Space>
        </div>
      </Spin>
    </Modal>
  );
};

export default CreateInvoiceRdaModal;
