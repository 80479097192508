import { Layout } from 'antd';

import Widget from './Widget';
import useAuth from '@hooks/use-auth';
import { Navigate } from 'react-router-dom';
import { DeleteCarrierModal } from './components/DeleteCarrierModal';
import PenaltyCarrierDetail from '@modules/PenaltyCarrierModal';

const CarriersPage = () => {
  let { data } = useAuth();

  if (
    data?.permissions.length === 1 &&
    !['manager', 'edit', 'view', 'superuser'].includes(data.permissions[0])
  ) {
    return <Navigate to="/" replace />;
  }
  return (
    <Layout style={{ height: '100%' }}>
      <DeleteCarrierModal />
      <PenaltyCarrierDetail />
      <Widget />
    </Layout>
  );
};

export default CarriersPage;
