import React from 'react';
import { Modal } from 'antd';

import { IPenaltyResult } from '@services/penalty';
import useModal, { IModalPayload } from '@hooks/use-modal';

import Widget from './Widget';
import { List } from 'lucide-react';

const PenaltyApplicationModal = () => {
  const [modal, setModal] =
    useModal<IModalPayload<IPenaltyResult[]>>('penalty-result');

  const onClose = () => {
    setModal({ isOpen: false, payload: null });
  };

  return (
    <Modal
      destroyOnClose
      centered
      width={640}
      onCancel={onClose}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
      footer={null}
      title={
        <>
          <List size={14} className="mr-2" />
          Results ({modal?.payload?.length})
        </>
      }
      visible={modal?.isOpen || false}
    >
      <Widget data={modal?.payload || []} />
    </Modal>
  );
};

export default PenaltyApplicationModal;
