import { IUser, IUserCreate, IUserResult } from './user.model';
import { apiRequest } from '@services/base';

const apiPrefix = process.env.REACT_APP_API_PATH_PREFIX;

export const createUser = async (body: IUserCreate) => {
  return apiRequest.post<IUserResult>(`${apiPrefix}/users/`, {
    ...body,
    permissions: [body.permissions],
  });
};

export const updateUser = async (id: string, body: Partial<IUser>) => {
  return apiRequest.put<IUserResult>(`${apiPrefix}/users/${id}`, {
    ...body,
    permissions: [body.permissions],
  });
};

export const getUsers = async (): Promise<any> => {
  return apiRequest.get<IUser[]>(`${apiPrefix}/users/`);
};

export const deleteUser = async (userId: string) => {
  return apiRequest.del(`${apiPrefix}/users/${userId}`);
};
