import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  Skeleton,
  Button,
  Space,
  Spin,
  notification,
  Alert,
  Typography,
} from 'antd';

import { handleAPIError, IFetchQuery } from '@services/base';
import { getPenalties, IPenalty, updatePenalties } from '@services/penalty';

import DataGrid from '../PenaltyApplicationModal/components/DataGrid';
import { SendHorizonal } from 'lucide-react';

const { Text } = Typography;

interface IProps {
  ids: string[];
  onClose: () => void;
}

const Widget = (props: IProps): JSX.Element | null => {
  const { ids, onClose } = props;
  const [data, setData] = useState<IPenalty[]>([]);
  const queryClient = useQueryClient();

  const { isFetching } = useQuery(
    ['update-penalty', ids.join('-')],
    () => {
      if (ids?.length) {
        return getPenalties({ ids: ids, page: 1, page_size: ids?.length });
      }
    },
    {
      keepPreviousData: false,
      onSuccess: res => {
        setData(
          (res?.penalties || []).map(item => {
            const { status } = item;
            return {
              ...item,
              message:
                ['BILLABLE'].indexOf(status || '') < 0 ? 'Invalid status' : '',
            };
          }),
        );
      },
      onError: handleAPIError,
    },
  );
  const { isLoading: isSubmitting, mutate } = useMutation(
    (payload: IFetchQuery[]) => {
      return updatePenalties(payload);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('penalties');
        onClose();
        notification.success({
          message: 'Submit invoices',
          description: 'Submitted successfully',
        });
      },
      onError: handleAPIError,
    },
  );

  const submit = () => {
    const payload: IFetchQuery[] = data
      .filter(({ message }) => !message)
      .map(item => {
        return {
          _id: item._id,
          status: 'BILLED',
          billed_at: new Date().toISOString(),
        };
      });
    mutate(payload);
  };

  if (isFetching) {
    return (
      <>
        <Skeleton />
        <Skeleton />
      </>
    );
  }

  if (data) {
    const validItems = data.filter(({ message }) => !message);
    const isValid = validItems.length;

    return (
      <Spin spinning={isSubmitting}>
        {validItems.length && validItems.length < data.length ? (
          <>
            <Alert
              message="The invalid items will be ignored after clicking ‘Confirm‘"
              type="warning"
              showIcon
            />
            <br />
          </>
        ) : null}
        {validItems.length === 0 ? (
          <>
            <Alert message="No valid items" type="error" showIcon />
            <br />
          </>
        ) : null}
        <DataGrid data={data} removable={false} />
        <br />
        {validItems.length ? (
          <>
            <Text>
              By clicking <strong>“Confirm”</strong> you will be submitting all
              the penalties in state <strong>“Billable”</strong> to the
              respective carriers. Do you want to proceed?
            </Text>
            <br />
            <br />
          </>
        ) : null}
        <div className={'text-center'}>
          <Space align={'center'}>
            <Button onClick={onClose}>Cancel</Button>
            <Button
              onClick={submit}
              disabled={!isValid}
              type="primary"
              icon={<SendHorizonal size={14} />}
            >
              Confirm ({validItems.length})
            </Button>
          </Space>
        </div>
      </Spin>
    );
  }

  return null;
};

export default Widget;
