import React from 'react';
import { Button, Dropdown, Menu } from 'antd';

import useAuth from '@hooks/use-auth';
import { ICarrier } from '@services/carrier';
import { Edit2, MoreVertical, Trash } from 'lucide-react';
import useModal, { IModalPayload } from '@hooks/use-modal';

const ActionCell = ({ data }: { data: ICarrier }) => {
  const { data: authInfo } = useAuth();
  const { flatPermissions = {} } = authInfo || {};
  const [, openCarrierDetails] =
    useModal<IModalPayload<{ id: string }>>('carrier-details');

  const [, deleteCarrier] =
    useModal<IModalPayload<{ carrierId: string }>>('delete-carrier');

  const normalActions = [
    {
      permissions: ['manager', 'suoperuser'],
      icon: <Edit2 size={16} />,
      onClick: () => {
        openCarrierDetails({
          isOpen: true,
          payload: {
            id: data.carrier_id as unknown as string,
          },
        });
      },
      label: 'Edit',
    },
    {
      permissions: ['manager', 'superuser'],
      icon: <Trash size={16} />,
      onClick: () =>
        deleteCarrier({
          isOpen: true,
          payload: { carrierId: data.carrier_id },
        }),
      label: 'Delete',
    },
  ];

  return (
    <Dropdown
      trigger={['click']}
      placement="bottomRight"
      overlay={
        <Menu
          items={normalActions
            .filter(item => {
              const { permissions = [] } = item;
              return permissions.length
                ? permissions.some(role => {
                    return flatPermissions[role];
                  })
                : true;
            })
            .map(item => {
              return {
                ...item,
                key: item.label,
              };
            })}
        />
      }
    >
      <Button type="text">
        <MoreVertical size={20} />
      </Button>
    </Dropdown>
  );
};

export default ActionCell;
