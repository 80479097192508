import React from 'react';
import useModal, { IModalPayload } from '@hooks/use-modal';
import { Modal } from 'antd';

import Widget from './Widget';
import { PencilLine } from 'lucide-react';

const PenaltyEditModal = () => {
  const [modal, setModal] =
    useModal<IModalPayload<{ ids: string[]; type: string }>>('update-penalty');

  const { ids = [] } = modal?.payload || {};

  const onClose = () => {
    setModal({ isOpen: false, payload: null });
  };

  return (
    <Modal
      destroyOnClose
      centered
      width={640}
      onCancel={onClose}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
      footer={null}
      title={
        <>
          <PencilLine size={14} className={'mr-2'} />
          Edit Penalty
        </>
      }
      visible={(modal?.isOpen && modal?.payload?.type === 'edit') || false}
    >
      <Widget ids={ids} onClose={onClose} />
    </Modal>
  );
};

export default PenaltyEditModal;
