import { useEffect } from 'react';
import { Form, Input, Radio, Button } from 'antd';
import { IUser } from '@services/user';

export interface IValues {
  displayName?: string;
  email?: string;
  permissions?: string[];
}

interface IProps {
  data: Partial<IUser>;
  onChange: (values: IValues) => void;
  submitValues: () => void;
}

const AddForm = (props: IProps) => {
  const { onChange, submitValues, data } = props;
  const [form] = Form.useForm();

  // Update form values when data prop changes
  useEffect(() => {
    form.setFieldsValue({
      displayName: data.displayName,
      email: data.email,
      permissions: data.permissions?.[0] || 'view',
    });
  }, [data, form]);

  const permissionsOptions = [
    { label: 'View', value: 'view' },
    { label: 'Finance', value: 'finance' },
    { label: 'User', value: 'edit' },
    { label: 'Superuser', value: 'superuser' },
    { label: 'Manager', value: 'manager' },
  ];

  const onPermissionChange = (permission: string) => {
    onChange({ permissions: [permission] });
  };

  const onFieldChange = (changedFields: any, allFields: any) => {
    const changedValue = changedFields[0];
    if (changedValue) {
      onChange({ [changedValue.name[0]]: changedValue.value });
    }
  };

  return (
    <Form
      form={form}
      onFieldsChange={onFieldChange}
      initialValues={{
        displayName: data.displayName,
        email: data.email,
        permissions: data.permissions?.[0] || 'view',
      }}
    >
      <Form.Item
        label="Name"
        name="displayName"
        rules={[{ required: true, message: 'Please enter the complete name' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Email"
        name="email"
        rules={[{ required: true, message: 'Please enter the email' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Permission"
        name="permissions"
        rules={[{ required: true, message: 'Please select a permission' }]}
      >
        <Radio.Group
          options={permissionsOptions}
          onChange={e => onPermissionChange(e.target.value)}
          optionType="button"
          buttonStyle="solid"
        />
      </Form.Item>

      <Button onClick={submitValues} type="primary">
        Confirm
      </Button>
    </Form>
  );
};

export default AddForm;
