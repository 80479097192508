import { useEffect, useState } from 'react';
import useModal, { IModalPayload } from '@hooks/use-modal';
import { Modal, notification } from 'antd';
import Form, { IValues } from './components/Form';
import { IUser, IUserCreate, createUser, updateUser } from '@services/user';
import { Edit2, Plus } from 'lucide-react';
import { useQueryClient } from 'react-query';

const AddUserModal = () => {
  const [modal, setModal] =
    useModal<IModalPayload<{ editing: boolean; data?: IUser | null }>>(
      'add-user',
    );
  const [user, setUser] = useState<IValues>({});
  const queryClient = useQueryClient();

  // Update user state when modal data changes
  useEffect(() => {
    if (modal?.payload?.data) {
      setUser({
        displayName: modal.payload.data.displayName,
        email: modal.payload.data.email,
        permissions: modal.payload.data.permissions,
      });
    } else {
      setUser({});
    }
  }, [modal?.payload?.data]);

  const onClose = () => {
    setUser({});
    setModal({ isOpen: false, payload: null });
  };

  const submitValues = async () => {
    if (!user.displayName || !user.email || !user.permissions?.length) {
      return notification.error({
        message: 'Invalid Payload',
        description: 'Please fill all the values correctly',
      });
    }

    try {
      if (modal?.payload?.editing) {
        await updateUser(modal.payload.data?._id as string, user);
        notification.success({
          message: 'Updated',
          description: 'User updated successfully',
        });
      } else {
        await createUser(user as IUserCreate);
        notification.success({
          message: 'Created',
          description: 'User created successfully',
        });
      }
      await queryClient.invalidateQueries('users');
      onClose();
    } catch (err) {
      notification.error({
        message: 'Error',
        description: modal?.payload?.editing
          ? 'Cannot update user'
          : 'Cannot create user',
      });
    }
  };

  const onChange = (data: IValues) => {
    setUser(prev => ({
      ...prev,
      ...data,
    }));
  };

  return (
    <Modal
      destroyOnClose
      centered
      width={640}
      onCancel={onClose}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
      footer={null}
      title={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {modal?.payload?.editing ? (
            <>
              <Edit2 size={18} />
              <span>Edit User</span>
            </>
          ) : (
            <>
              <Plus size={18} />
              <span>Add User</span>
            </>
          )}
        </div>
      }
      visible={modal?.isOpen || false}
    >
      <Form onChange={onChange} submitValues={submitValues} data={user} />
    </Modal>
  );
};

export default AddUserModal;
