import { Form, Input } from 'antd';
import { ICarrier } from '@services/carrier';

export interface IValues {
  carrier_id?: string;
  carrier_name?: string;
  email?: string;
  address?: string;
  vat?: string;
  link?: string;
  finance_id?: string;
  contacts?: {
    financial?: string;
    notifications?: string;
  };
}

interface IProps {
  editable: IValues;
  carrier: ICarrier;
  onChange: (values: IValues) => void;
}

const CarrierForm = (props: IProps) => {
  const { carrier, editable, onChange } = props;
  return (
    <Form>
      <label htmlFor="name">Name</label>
      <Input
        name="name"
        value={editable.carrier_name}
        onChange={e => {
          onChange({ carrier_name: e.target.value });
        }}
      ></Input>
      <label htmlFor="id">ID</label>
      <Input name="id" value={carrier.carrier_id} disabled={true}></Input>
      <label htmlFor="email">Main Email</label>
      <Input
        onChange={e => {
          onChange({ email: e.target.value });
        }}
        name="email"
        value={editable.email}
      ></Input>
      <label htmlFor="address">Address</label>
      <Input
        onChange={e => {
          onChange({ address: e.target.value });
        }}
        name="address"
        value={editable.address}
      ></Input>
      <label htmlFor="vat">VAT</label>
      <Input
        onChange={e => {
          onChange({ vat: e.target.value });
        }}
        name="vat"
        value={editable.vat}
      ></Input>
      <label htmlFor="finance-id">Finance ID</label>
      <Input
        onChange={e => {
          onChange({ finance_id: e.target.value });
        }}
        name="vat"
        type="number"
        value={editable.finance_id}
      ></Input>
      <label htmlFor="address">Financial Email</label>

      <Input
        onChange={e => {
          onChange({
            contacts: { ...editable.contacts, financial: e.target.value },
          });
        }}
        name="contact_financial"
        value={editable.contacts?.financial}
      ></Input>

      <label htmlFor="address">Notifications Email</label>

      <Input
        onChange={e => {
          onChange({
            contacts: { ...editable.contacts, notifications: e.target.value },
          });
        }}
        name="contact_notifications"
        value={editable.contacts?.notifications}
      ></Input>
    </Form>
  );
};

export default CarrierForm;
