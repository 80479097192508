import React from 'react';
import { Button, Space, Tooltip, Typography } from 'antd';

import useModal, { IModalPayload } from '@hooks/use-modal';
import classNames from 'classnames';
import { useExportPenalty } from '@hooks/use-export-penalty';
import useAuth from '@hooks/use-auth';
import {
  ChevronLeft,
  CircleCheck,
  FileText,
  GitPullRequestArrow,
  LoaderCircle,
  PenLine,
  Save,
  Settings,
  Share,
  Tags,
  Trash,
  X,
} from 'lucide-react';

const { Text } = Typography;

const BulkActions = ({
  ids,
  onProcessingStart,
  onProcessingEnd,
  processing,
  processingTime,
}: {
  ids: string[];
  onProcessingStart: any;
  onProcessingEnd: any;
  processing: boolean;
  processingTime: number;
}) => {
  const [, updatePenalty] =
    useModal<IModalPayload<{ ids: string[]; type: string; time?: number }>>(
      'update-penalty',
    );
  const { isExporting, exportToCSV } = useExportPenalty();
  const { data } = useAuth();
  const { flatPermissions = {} } = data || {};
  const processingActions = [
    {
      colorType: 'danger',
      permissions: ['edit', 'superuser', 'manager'],
      icon: <Settings size={14} />,
      label: 'Stop Processing',
      onClick: () => {
        onProcessingEnd();
      },
    },

    {
      colorType: 'default',
      permissions: ['edit', 'superuser', 'manager'],
      icon: <CircleCheck size={14} />,
      label: 'Apply penalty',
      onClick: () => {
        updatePenalty({
          isOpen: true,
          payload: {
            type: 'apply',
            ids,
            time: processingTime,
          },
        });
      },
    },
    {
      colorType: 'default',

      permissions: ['edit', 'superuser', 'manager'],
      icon: <X size={14} />,
      label: 'Remove penalty',
      onClick: () => {
        updatePenalty({
          isOpen: true,
          payload: {
            type: 'remove',
            ids,
            time: processingTime,
          },
        });
      },
    },
  ];
  const normalActions = [
    {
      colorType: 'primary',

      permissions: ['edit', 'superuser', 'manager'],
      icon: <Settings size={14} />,
      label: 'Start processing',
      onClick: () => {
        onProcessingStart(ids);
      },
    },
    {
      colorType: 'default',

      permissions: ['edit', 'view', 'superuser', 'manager'],
      icon: isExporting ? <LoaderCircle size={14} /> : <Share size={14} />,
      label: 'Export',
      onClick: () => {
        exportToCSV({ ids });
      },
    },

    {
      colorType: 'default',

      permissions: ['edit', 'superuser', 'manager'],
      icon: <PenLine size={14} />,
      label: 'Edit Penalty',
      onClick: () => {
        updatePenalty({
          isOpen: true,
          payload: {
            ids,
            type: 'edit',
          },
        });
      },
    },

    {
      colorType: 'default',

      permissions: ['edit', 'superuser', 'manager'],
      icon: <FileText size={14} />,
      label: 'Invoice penalties',
      onClick: () => {
        updatePenalty({
          isOpen: true,
          payload: {
            type: 'invoice',
            ids,
          },
        });
      },
    },
    {
      colorType: 'default',

      permissions: ['edit', 'superuser', 'manager'],
      icon: <GitPullRequestArrow size={14} />,
      label: 'Request information',
      onClick: () => {
        updatePenalty({
          isOpen: true,
          payload: {
            type: 'request-information',
            ids,
          },
        });
      },
    },
    {
      colorType: 'default',

      permissions: ['edit', 'superuser', 'manager'],
      icon: <Tags size={14} />,
      label: 'Assign tag',
      onClick: () => {
        updatePenalty({
          isOpen: true,
          payload: {
            type: 'assign-tag',
            ids,
          },
        });
      },
    },
    {
      colorType: 'default',

      permissions: ['manager'],
      icon: <ChevronLeft size={14} />,
      label: 'Reset to pending',
      onClick: () => {
        updatePenalty({
          isOpen: true,
          payload: {
            type: 'reset-penalty',
            ids,
          },
        });
      },
    },
    {
      colorType: 'default',

      permissions: ['superuser', 'manager'],
      icon: <Save size={14} />,
      label: 'Archive',
      onClick: () => {
        updatePenalty({
          isOpen: true,
          payload: {
            type: 'archive',
            ids,
          },
        });
      },
    },
    {
      colorType: 'default',

      permissions: ['superuser', 'manager'],
      icon: <Trash size={14} />,
      label: 'Delete',
      onClick: () => {
        updatePenalty({
          isOpen: true,
          payload: {
            type: 'delete',
            ids,
          },
        });
      },
    },
  ];
  const actions = processing ? processingActions : normalActions;

  const getActionItem = (item: any) => {
    const { permissions = [] } = item;
    const hasPermissions = permissions.length
      ? permissions.some((role: any) => {
          return flatPermissions[role];
        })
      : true;

    if (hasPermissions) {
      return (
        <Tooltip key={item.label} title={item.label}>
          <Button onClick={item.onClick} type={item.colorType}>
            {item.icon}
          </Button>
        </Tooltip>
      );
    }
    return null;
  };
  const actionsItems = actions.map(item => getActionItem(item));

  return (
    <div
      className={classNames('bulk-actions', {
        'bulk-actions--active': true,
      })}
    >
      <Space>
        <Text>
          {processing ? (
            <Text>
              Processing <strong className="mr-1">{ids.length}</strong>{' '}
              penalties
            </Text>
          ) : (
            <Text>
              <strong className="mr-1">{ids.length}</strong> selected items
            </Text>
          )}
        </Text>
        {actionsItems}
      </Space>
    </div>
  );
};

export default BulkActions;
