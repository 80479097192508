import { Layout } from 'antd';

import Widget from './Widget';
import AddUserModal from '@modules/AddUserModal';
import useAuth from '@hooks/use-auth';
import { Navigate } from 'react-router-dom';
import { DeleteUserModal } from './components/DeleteUserModal';

const TeamPage = () => {
  let { data } = useAuth();

  if (
    data?.permissions.length === 1 &&
    !['superuser', 'manager'].includes(data?.permissions[0])
  ) {
    return <Navigate to="/" replace />;
  }
  return (
    <Layout style={{ height: '100%' }}>
      <DeleteUserModal />
      <AddUserModal />
      <Widget />
    </Layout>
  );
};

export default TeamPage;
