import React from 'react';
import {
  Badge,
  Button,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Tag,
} from 'antd';
import { useQuery } from 'react-query';

import { getCarriers } from '@services/carrier';
import { IInvoiceFilter } from '@services/invoice/invoice.model';
import styles from './grid-filter.module.scss';
import isArray from 'lodash/isArray';
import { formatDate } from '@utils/utilities';
import { Filter } from 'lucide-react';

interface IProps {
  data: IInvoiceFilter;
  onFilter: (values: IInvoiceFilter) => void;
  onCsvDownload: (values: IInvoiceFilter) => void;
  onCsvFinanceDownload: (values: IInvoiceFilter) => void;
}
const GridFilter = (props: IProps): JSX.Element => {
  const { onFilter, data, onCsvDownload, onCsvFinanceDownload } = props;
  const [form] = Form.useForm();

  const { data: carriers } = useQuery('carriers', () => getCarriers(), {});
  const uniqueCarrierIds = new Set();
  const uniqueCarriers = carriers?.carriers
    ?.filter(item => {
      if (!uniqueCarrierIds.has(item.carrier_id)) {
        uniqueCarrierIds.add(item.carrier_id);
        return true;
      }
      return false;
    })
    .map(item => ({
      label: item.carrier_name,
      value: item.carrier_id,
    }));
  const filters = [
    {
      label: 'Invoice Number',
      field: 'invoice_number',
      type: 'text',
    },
    {
      label: 'Type',
      field: 'type',
      type: 'select',
      values: [
        { label: 'RDA', value: 'RDA' },
        { label: 'RDP', value: 'RDP' },
      ] as any[],
    },
    {
      label: 'Emission Range',
      field: 'emission_range',
      type: 'range-picker',
    },
    {
      label: 'Competence Range',
      field: 'competence_range',
      type: 'range-picker',
    },
    {
      label: 'Carrier',
      field: 'carrier_id',
      type: 'select',
      values: uniqueCarriers || [],
    },
    {
      label: 'Sennder Reference',
      field: 'sennder_reference',
      type: 'text',
    },
    {
      label: 'Customer Reference',
      field: 'customer_reference',
      type: 'text',
    },
    {
      label: 'Tags',
      field: 'tags',
      type: 'tags',
    },
    {
      label: 'Status',
      field: 'status',
      type: 'select',
      values: [
        { label: 'Invoiced', value: 'INVOICED' },
        { label: 'Accounted', value: 'ACCOUNTED' },
        { label: 'Compensated', value: 'COMPENSATED' },
      ] as any[],
    },
  ];

  const onReset = () => {
    form.resetFields();
    form.submit();
  };

  const onSubmit = (values: IInvoiceFilter) => {
    onFilter(values);
  };

  const onCsvExport = (values: IInvoiceFilter) => {
    onCsvDownload(values);
  };

  const onCsvFinanceExport = (values: IInvoiceFilter) => {
    onCsvFinanceDownload(values);
  };
  const content = (
    <div className={styles.filterDropdown} style={{ width: '90%' }}>
      <Form
        layout="vertical"
        form={form}
        initialValues={
          data.emission_range ? { emission_range: data.emission_range } : {}
        }
        onFinish={onSubmit}
      >
        <div onClick={e => e.stopPropagation()}>
          <Row gutter={12}>
            {filters.map(item => {
              return (
                <Col key={item.field} span={12}>
                  {item.type === 'number' ? (
                    <Form.Item label={item.label} name={item.field}>
                      <Input autoComplete="off" type="number" />
                    </Form.Item>
                  ) : null}
                  {item.type === 'text' ? (
                    <Form.Item label={item.label} name={item.field}>
                      <Input autoComplete="off" />
                    </Form.Item>
                  ) : null}
                  {item.type === 'select' && item.values ? (
                    <Form.Item label={item.label} name={item.field}>
                      <Select
                        showSearch={item.values.length > 5}
                        allowClear
                        virtual={false}
                        filterOption={(
                          inputValue,
                          option, //Filter label instead of value since the value is the carrier ID
                        ) =>
                          (option?.children as unknown as string)
                            .toString()
                            .toLowerCase()
                            .includes(inputValue.toString().toLowerCase())
                        }
                      >
                        {item.values.map(({ label, value }: any) => {
                          return (
                            <Select.Option key={value} value={value}>
                              {label}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  ) : null}
                  {item.type === 'tags' ? (
                    <Form.Item label={item.label} name={item.field}>
                      <Select
                        mode="tags"
                        showSearch={false}
                        notFoundContent={'Enter the tags'}
                      />
                    </Form.Item>
                  ) : null}
                  {item.type === 'radio' && item.values ? (
                    <Form.Item label={item.label} name={item.field}>
                      <Radio.Group>
                        {item.values.map(({ label, value }: any) => {
                          return (
                            <Radio.Button key={value} value={value}>
                              {label}
                            </Radio.Button>
                          );
                        })}
                      </Radio.Group>
                    </Form.Item>
                  ) : null}
                  {item.type === 'range-picker' ? (
                    <Form.Item label={item.label} name={item.field}>
                      <DatePicker.RangePicker format="YYYY-MM-DD" />
                    </Form.Item>
                  ) : null}
                </Col>
              );
            })}
          </Row>
        </div>
        <Row gutter={12} justify="end">
          <Col>
            <Button onClick={onReset}>Reset</Button>
          </Col>
          <Col>
            <Button type="ghost" onClick={() => onCsvExport(data)}>
              Export CSV
            </Button>
          </Col>
          <Col>
            <Button type="ghost" onClick={() => onCsvFinanceExport(data)}>
              Export Finance CSV
            </Button>
          </Col>
          <Col>
            <Button type="primary" htmlType="submit">
              Apply
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );

  let counter = 0;
  const keys = Object.keys(data);

  keys.forEach(key => {
    const value = data[key as keyof IInvoiceFilter];
    if (
      value !== undefined &&
      value !== '' &&
      (!isArray(value) || value.length > 0)
    ) {
      counter = counter + 1;
    }
  });

  const removeTag = (key: string) => {
    return () => {
      form.setFieldsValue({
        ...data,
        [key]: undefined,
      });
      form.submit();
      onFilter({ ...data, [key]: undefined });
    };
  };

  return (
    <div className={styles.filter}>
      <Space>
        <Dropdown overlay={content} trigger={['click']}>
          <div className="mr-3">
            <Badge count={counter}>
              <Button
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '4px 8px',
                }}
              >
                <Filter size={14} />
                <span>&nbsp; Filter</span>
              </Button>
            </Badge>
          </div>
        </Dropdown>

        {filters.map(({ type, label, field, values }) => {
          const value = data[field as keyof IInvoiceFilter];
          if (value && (!isArray(value) || value.length > 0)) {
            return (
              <Tag
                onClose={removeTag(field)}
                closable
                key={`${field}-${value}`} // Ensure unique key
                style={{ padding: '4px 8px' }}
              >
                <strong>{label}: </strong>
                {type === 'number' ? value : null}
                {type === 'text' ? value : null}
                {(type === 'select' &&
                  !isArray(value) &&
                  values?.find((item: any) => item.value === value)?.label) ||
                  null}
                {(type === 'select' &&
                  isArray(value) &&
                  value
                    .map(
                      child =>
                        values?.find((item: any) => item.value === child)
                          ?.label,
                    )
                    .join(', ')) ||
                  null}
                {type === 'tags' && isArray(value) ? value.join(', ') : null}
                {type === 'range-picker'
                  ? `${formatDate(
                      value.toString().split(',')[0],
                    )} - ${formatDate(value.toString().split(',')[1])}`
                  : null}
                {(type === 'radio' &&
                  values?.find((item: any) => item.value === value)?.label) ||
                  null}
              </Tag>
            );
          }
          return null;
        })}
      </Space>
    </div>
  );
};

export default GridFilter;
